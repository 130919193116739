<template>
  <div class="bt-page-step-item w-full">
    <v-card
      class="elevation-3 rounded-lg pa-5 mb-3"
      :style="{
        backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg)' : 'var(--light-bg)',
      }"
    >
      <h6 class="text-h6 font-weight-bold mb-4">Data Pasangan</h6>
      <v-row>
        <v-col cols="12">
          <v-text-field :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.pasangan_nama" label="Nama" />
        </v-col>
        <v-col cols="12">
          <v-autocomplete :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined :items="list.tempat_lahir" v-model="form.pasangan_tempat_lahir" label="Tempat Lahir" />
        </v-col>
        <v-col cols="12">
          <v-text-field type="date" :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.pasangan_tanggal_lahir" label="Tanggal Lahir" />
        </v-col>
        <v-col cols="12">
          <v-text-field :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.pasangan_usia" label="Usia" readonly />
        </v-col>
        <v-col cols="12">
          <v-text-field type="number" :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.pasangan_no_ktp" label="No KTP" />
        </v-col>
        <v-col cols="12">
          <v-text-field type="number" :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.pasangan_no_hp" label="No HP/Telp" />
        </v-col>
        <v-col cols="12">
          <v-autocomplete :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined :items="list.pendidikan" v-model="form.pasangan_pendidikan" label="Pendidikan" />
        </v-col>
        <v-col cols="12">
          <v-autocomplete :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined :items="list.pekerjaan" v-model="form.pasangan_pekerjaan" label="Pekerjaan" />
        </v-col>
        <v-col cols="12">
          <v-text-field type="number" :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.pasangan_pendapatan" label="Pendapatan (per bulan)" required />
        </v-col>
        <v-col cols="12">
          <v-textarea :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.pasangan_keterangan_pekerjaan" label="Keterangan Pekerjaan" />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
            autocomplete="off"
            outlined
            :items="list.literasi_latin"
            v-model="form.pasangan_literasi_latin"
            label="Literasi Baca Tulis Latin"
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined :items="list.literasi_arab" v-model="form.pasangan_literasi_arab" label="Literasi Baca Tulis Arab" />
        </v-col>
        <v-col cols="12">
          <v-text-field
            type="number"
            :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
            autocomplete="off"
            outlined
            v-model="form.jumlah_keluarga"
            label="Jumlah Keluarga"
            required
            :rules="[(v) => !!v || 'Jumlah Keluarga is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            type="number"
            :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
            autocomplete="off"
            outlined
            v-model="form.jumlah_tanggungan"
            label="Jumlah Tanggungan"
            required
            :rules="[(v) => !!v || 'Jumlah Tanggungan is required']"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["form", "list"],
};
</script>

<style scoped>
/* Add your styles here */
</style>
